import { render, staticRenderFns } from "./WorkoutHistory.vue?vue&type=template&id=45a0633e&scoped=true&"
import script from "./WorkoutHistory.vue?vue&type=script&lang=js&"
export * from "./WorkoutHistory.vue?vue&type=script&lang=js&"
import style0 from "./WorkoutHistory.vue?vue&type=style&index=0&id=45a0633e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45a0633e",
  null
  
)

export default component.exports