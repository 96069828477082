<template>
  <div>
      <build-workout />
  </div>

</template>

<script>
import BuildWorkout from '../components/BuildWorkout.vue'
export default {
    components: {
        BuildWorkout
    }
}
</script>

<style>

</style>